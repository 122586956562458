import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreService } from 'src/app/core/services/core.service';
import { DashboardsService } from 'src/app/core/services/dasboards.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { ChartDataSet } from 'src/app/standalone components/chart/types/chart-data-set';

@Component({
  selector: 'app-apvs-atende',
  templateUrl: './apvs-atende.component.html',
  styleUrls: ['./apvs-atende.component.scss']
})
export class ApvsAtendeComponent implements OnInit {


  constructor(
    private dashboardService: DashboardsService,
    private coreService: CoreService,
    public usr: UserProfileService,
  ) { }

  /*  --  ---  --  --  --  --  Charts datasets  --  --  --  --  --  --    --  --  */

  // Estoque
  public statusDataSet: ChartDataSet[] = [];
  public auctionDataSet: ChartDataSet[] = [];
  public monthDataSet: ChartDataSet[] = [];
  public typeDataSet: ChartDataSet[] = [];


  // Produtividade
  public analysisTimeDataSet: ChartDataSet[] = [];
  public averageAnalysisTimeDataSet: ChartDataSet[] = [];
  public averageTime: number = 0;


  /*  --  --  --  --  --  --  --  --  --  --  --  --  --  --  --  --  --  --  --  */

  public loadedData: any = {
    statusData: false,
    auctionData: false,
    monthData: false,
    typeData: false,
    analysisTimeData: false,
    averageAnalysisTimeData: false
  }

  public permissions: any = {
    analyst_dashboard: false,
  }

  // dates
  public startDate: string = new Date().toISOString().split('T')[0];
  public endDate: string = new Date().toISOString().split('T')[0];

  // page controll
  public page: number = 1;

  async ngOnInit() {
    this.permissions.analyst_dashboard = await this.usr.verifyPermission('analyst_dashboard');
    let tresMeses = 90 * 24 * 60 * 60 * 1000;
    this.startDate = (new Date(Date.now() - tresMeses)).toISOString().split('T')[0];
    if (this.permissions.analyst_dashboard)
      this.setCharts();
  }

  inventoryDashboard() {
    this.dashboardService.getInventoryDashboardData({ dt_inicio: this.startDate, dt_fim: this.endDate }).subscribe({
      next: (data: any) => {
        if (data.status) {
          this.statusDataSet = this.setDataLayout(data.data.statusData);
          this.monthDataSet = this.setDataLayout(data.data.monthData);
          this.typeDataSet = this.setDataLayout(data.data.typeData);
          this.auctionDataSet = this.setDataLayout(data.data.auctionData);
          this.loadedData.statusData = true;
          this.loadedData.auctionData = true;
          this.loadedData.monthData = true;
          this.loadedData.typeData = true;
        }
      },
      error: (error: any) => {
        console.error(error);
        this.coreService.hideLoading();
      }
    })
  }

  productivityDashboard() {
    this.dashboardService.getProductivityDashboardData({ dt_inicio: this.startDate, dt_fim: this.endDate }).subscribe({
      next: (data: any) => {
        if (data.status) {
          this.analysisTimeDataSet = [...this.setDataLayout(data.data.analysis_time, 'line', '#d10a32'), ...this.setDataLayout(data.data.number_of_proposals, 'bar', '#192753')];
          this.averageAnalysisTimeDataSet = this.setAvarageDataLayout(data.data.analysis_time, data.data.number_of_proposals);
          this.averageTime = data.data.average_time;
          this.loadedData.analysisTimeData = true;
          this.loadedData.averageAnalysisTimeData = true;
        }
      },
      error: (error: any) => {
        console.error(error);
        this.coreService.hideLoading();
      }
    })
  }

  setDataLayout(data: any, type?: string, color?: string) {
    let dashboardData: ChartDataSet[] = [];
    Object.keys(data.data).forEach((key) => {
      dashboardData.push({ key: key, value: data.data[key], label: data.label, type: type, color: color });
    })
    return dashboardData;
  }

  setAvarageDataLayout(data1: any, data2: any) {
    let dashboardData: ChartDataSet[] = [];
    Object.keys(data1.data).forEach((key) => {
      dashboardData.push({ key: key, value: parseInt((data1.data[key] / data2.data[key]).toFixed(2)), label: "Media de tempo de análise por proposta (dias)" });
    })
    return dashboardData;
  }


  setCharts() {

    this.loadedData = {
      statusData: false,
      auctionData: false,
      monthData: false,
      typeData: false,
      analysisTimeData: false,
      averageAnalysisTimeData: false
    }

    switch (this.page) {
      case 1:
        this.inventoryDashboard();
        break;
      case 2:
        this.productivityDashboard();
        break;
      default:
        break;
    }
  }

  setPage(page: number) {
    if (page !== this.page) {
      this.page = page;
      this.setCharts();
    }
  }

  /**
   * Recent Activity
   */
  toggleActivity() {
    const recentActivity = document.querySelector('.layout-rightside-col');
    if (recentActivity != null) {
      recentActivity.classList.toggle('d-none');
    }

    if (window.screen.width <= 767) {
      const recentActivity = document.querySelector('.layout-rightside-col');
      if (recentActivity != null) {
        recentActivity.classList.add('d-block');
        recentActivity.classList.remove('d-none');
      }
    }
  }

  /**
   * SidebarHide modal
   * @param content modal content
   */
  SidebarHide() {
    const recentActivity = document.querySelector('.layout-rightside-col');
    if (recentActivity != null) {
      recentActivity.classList.remove('d-block');
    }
  }

}
