<!-- start faqs -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="text-center mb-5">
                    <h3 class="mb-3 fw-semibold">Frequently Asked Questions</h3>
                    <p class="text-muted mb-4 ff-secondary">If you can not find answer to your question in our FAQ, you can
                        always contact us or email us. We will answer you shortly!</p>

                    <div class="">
                        <button type="button" class="btn btn-primary btn-label rounded-pill me-1"><i class="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i> Email Us</button>
                        <button type="button" class="btn btn-info btn-label rounded-pill"><i class="ri-twitter-line label-icon align-middle rounded-pill fs-16 me-2"></i> Send Us Tweet</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->

        <div class="row g-lg-5 g-4">
            <div class="col-lg-6">
                <div class="d-flex align-items-center mb-2">
                    <div class="flex-shrink-0 me-1">
                        <i class="ri-question-line fs-24 align-middle text-success me-1"></i>
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="mb-0 fw-semibold">General Questions</h5>
                    </div>
                </div>
                <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
                    <ngb-panel id="static-1">
                        <ng-template ngbPanelTitle class="accordion-header">
                            <h2 class="accordion-header" id="genques-headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#genques-collapseOne" aria-expanded="true"
                                    aria-controls="genques-collapseOne">
                                    What is the purpose of using themes ?
                                </button>
                            </h2>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="accordion-body ff-secondary">
                                A theme is a set of colors, fonts, effects, and more that can be applied to your entire presentation to give it a
                                consistent, professional look. You've already been using a theme, even if you didn't know it: the default Office theme,
                                which consists.
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-2">
                        <ng-template ngbPanelTitle class="accordion-header">
                            <h2 class="accordion-header" id="genques-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#genques-collapseTwo" aria-expanded="false"
                                    aria-controls="genques-collapseTwo">
                                    Can a theme have more than one theme?
                                </button>
                            </h2>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="accordion-body ff-secondary">
                                A story can have as many themes as the reader can identify based on recurring patterns and parallels within the story
                                itself. In looking at ways to separate themes into a hierarchy, we might find it useful to follow the example of a
                                single book.
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-3">
                        <ng-template ngbPanelTitle class="accordion-header">
                            <h2 class="accordion-header" id="genques-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#genques-collapseThree" aria-expanded="false"
                                    aria-controls="genques-collapseThree">
                                    What are theme features?
                                </button>
                            </h2>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div id="genques-collapseThree" class="accordion-collapse collapse"
                            aria-labelledby="genques-headingThree" data-bs-parent="#genques-accordion">
                            <div class="accordion-body ff-secondary">
                                Theme features is a set of specific functionality that may be enabled by theme authors. Themes must register each
                                individual Theme Feature that the author wishes to support. Theme support functions should be called in the theme's
                                functions.
                            </div>
                        </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-4">
                      <ng-template ngbPanelTitle class="accordion-header">
                        <h2 class="accordion-header" id="genques-headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#genques-collapseFour" aria-expanded="false"
                                aria-controls="genques-collapseFour">
                                What is simple theme?
                            </button>
                        </h2>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <div class="accordion-body ff-secondary">
                            Simple is a free WordPress theme, by Themify, built exactly what it is named for: simplicity. Immediately upgrade the
                            quality of your WordPress site with the simple theme To use the built-in Chrome theme editor.
                        </div>
                      </ng-template>
                  </ngb-panel>
                </ngb-accordion>

            </div>
            <!-- end col -->
            <div class="col-lg-6">
                <div class="d-flex align-items-center mb-2">
                    <div class="flex-shrink-0 me-1">
                        <i class="ri-shield-keyhole-line fs-24 align-middle text-success me-1"></i>
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="mb-0 fw-semibold">Privacy &amp; Security</h5>
                    </div>
                </div>

                <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
                    <ngb-panel id="static-1">
                        <ng-template ngbPanelTitle class="accordion-header">
                            <h2 class="accordion-header" id="privacy-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#privacy-collapseOne" aria-expanded="false"
                                    aria-controls="privacy-collapseOne">
                                    Does Word have night mode?
                                </button>
                            </h2>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="accordion-body ff-secondary">
                                You can run Microsoft Word in dark mode, which uses a dark color palette to help reduce eye strain in low light
                                settings. You can choose to make the document white or black using the Switch Modes button in the ribbon's View tab.
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-2">
                        <ng-template ngbPanelTitle class="accordion-header">
                            <h2 class="accordion-header" id="privacy-headingTwo">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#privacy-collapseTwo" aria-expanded="true"
                                    aria-controls="privacy-collapseTwo">
                                    Is theme an opinion?
                                </button>
                            </h2>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="accordion-body ff-secondary">
                                A theme is an opinion the author expresses on the subject, for instance, the author's dissatisfaction with the narrow
                                confines of French bourgeois marriage during that period theme is an idea that a writer repeats.
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-3">
                        <ng-template ngbPanelTitle class="accordion-header">
                            <h2 class="accordion-header" id="privacy-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#privacy-collapseThree" aria-expanded="false"
                                    aria-controls="privacy-collapseThree">
                                    How do you develop a theme?
                                </button>
                            </h2>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div id="genques-collapseThree" class="accordion-collapse collapse"
                            aria-labelledby="genques-headingThree" data-bs-parent="#genques-accordion">
                            <div class="accordion-body ff-secondary">
                                A short story, novella, or novel presents a narrative to its reader. Perhaps that narrative involves mystery, terror,
                                romance, comedy, or all of the above. These works of fiction may also contain memorable characters, vivid
                                world-building, literary devices.
                                </div>
                        </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-4">
                      <ng-template ngbPanelTitle class="accordion-header">
                        <h2 class="accordion-header" id="privacy-headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#privacy-collapseFour" aria-expanded="false"
                                aria-controls="privacy-collapseFour">
                                Do stories need themes?
                            </button>
                        </h2>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <div class="accordion-body ff-secondary">
                            A story can have as many themes as the reader can identify based on recurring patterns and parallels within the story
                            itself. In looking at ways to separate themes into a hierarchy, we might find it useful to follow the example of a
                            single book.
                        </div>
                      </ng-template>
                  </ngb-panel>
                </ngb-accordion>
                <!--end accordion-->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end faqs -->