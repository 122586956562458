import { Injectable } from "@angular/core";
import { IChart } from "../interfaces/i-chart";
import { BarChart } from "./bar-chart";
import { LineChart } from "./line-chart";
import { PieChart } from "./pie-chart";
import { HorizontalBarChart } from "./horizontal-bar-chart";
import { DoughnutChart } from "./doughnut-chart";

/**
 * Chart Factory
 * @description
 * Classe usada para facilitar a geração de gráficos
 */
@Injectable({
    providedIn: 'root',
})
export class ChartFactory {

    public defaultColors = [
        "rgba(52, 152, 219, 0.7)",
        "rgba(231, 76, 60, 0.7)",
        "rgba(241, 196, 15, 0.7)",
        "rgba(155, 89, 182, 0.7)",
        "rgba(52, 73, 94, 0.7)",
        "rgba(230, 126, 34, 0.7)",
        "rgba(149, 165, 166, 0.7)",
        "rgba(46, 204, 113, 0.7)",
        "rgba(236, 240, 241, 0.7)",
        "rgba(26, 188, 156, 0.7)",
        "rgba(127, 140, 141, 0.7)"
    ];
    


    /**
     * Mapa de variacões de gráficos
     * Começando com '-' não há variação (variação '')
     */
    private chartVariationMap: { [key: string]: IChart } = {
        "-bar": new BarChart(),
        "-line": new LineChart(),
        "-pie": new PieChart(),
        "-doughnut": new DoughnutChart(),
        "horizontal-bar": new HorizontalBarChart(),
    };

    createChart(chartType: string, variation: string = '', colors: string[] = this.defaultColors): IChart {
        const ChartClass = this.chartVariationMap[`${variation}-${chartType}`];
        if (!ChartClass) {

            throw new Error(`Tipo de gráfico ainda não mapeado: ${chartType}`);
        }
        ChartClass.setColors(colors);
        return ChartClass;
    }
}
