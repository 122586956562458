import { ChartDataSet } from "../../types/chart-data-set";
import { DoughnutChartData } from "../../types/doughnut-chart-data";
import { IChart } from "../interfaces/i-chart";

export class DoughnutChart implements IChart {

    constructor() { }

    getChartOptions(data: ChartDataSet[] = []): any {
        let options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom',
                },
                title: {
                    display: true,
                    text: data[0].label ?? 'Sem título'
                }
            }
        };

        return options;
    }

    colors!: string[];

    setColors(colors: string[]): void {
        this.colors = colors;
    }

    /**
     * Gera um gráfico de barras
     * @param data dados do gráfico (Ex.: [{ key: 'Sales', value: 20 }, { key: 'Revenue', value: 10 }])
     * @returns DoughnutChartData
     */
    public getChartData(data: ChartDataSet[]): DoughnutChartData {

        const datasets: any = this.buildDataSet(data);
        const doughnutChartData: DoughnutChartData = datasets;

        return doughnutChartData;
    }

    private buildDataSet(data: ChartDataSet[]): any {
        var keys = data.map((d) => d.key);
        var values = data.map((d) => d.value);
        var dataset =
        {
            labels: keys,
            datasets: [{
                data: values,
                backgroundColor: this.colors,
            }],
        }

        return dataset;
    }

}