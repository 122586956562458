import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Feather Icon
import { FeatherModule } from "angular-feather";
import { allIcons } from "angular-feather/icons";
// Apex Chart Package
import { NgApexchartsModule } from "ng-apexcharts";

import { BestSellingComponent } from "./dashboard/best-selling/best-selling.component";
import { TopSellingComponent } from "./dashboard/top-selling/top-selling.component";
import { RecentOrdersComponent } from "./dashboard/recent-orders/recent-orders.component";

@NgModule({
  declarations: [
    BestSellingComponent,
    TopSellingComponent,
    RecentOrdersComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FeatherModule.pick(allIcons),
    NgApexchartsModule,
  ],
  exports: [BestSellingComponent, TopSellingComponent, RecentOrdersComponent],
})
export class WidgetModule {}
