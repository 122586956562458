import { ChartDataSet } from "../chart/types/chart-data-set";

export class ChartHelper {
    constructor() { }

    static groupDataByLabel(data: ChartDataSet[]): Record<string, ChartDataSet[]> {
        const groupedData = data.reduce((acc: any, curr: ChartDataSet) => {
            if (!curr.label) curr.label = 'No Label';
            if (!acc[curr.label]) acc[curr.label] = { data: [], colors: [], type: null };
            else {
                acc[curr.label].data.push(curr);
                acc[curr.label].colors.push(curr.color);
                acc[curr.label].type = (curr.type);
            }
            acc[curr.label].colors = acc[curr.label].colors.filter((color: string) => color !== undefined);
            acc[curr.label].colors = acc[curr.label].colors.filter((color: string) => color !== null);
            return acc;
        }, {} as Record<string, ChartDataSet[]>);
        return groupedData;
    }

    static empty(array: any): boolean {
        return !array || array.length === 0
    }

}