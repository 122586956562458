<header *ngIf="user" id="page-topbar">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box horizontal-logo">
                    <a href="" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="17">
                        </span>
                    </a>

                    <a href="" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="17">
                        </span>
                    </a>
                </div>

                <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                    id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>

                <button hidden type="button"
                    class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger ion-hide-md-up"
                    id="topnav-hamburger-icon" (click)="closeMenu()">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>
              
                <h4 *ngIf="!isProd" title="Você está navegando pelo ambiente de homologação" style="margin-top: 25px;">HOMOLOGAÇÃO</h4>

                <!-- App Search-->
                <form hidden class="app-search d-none d-md-block">
                    <div hidden class="position-relative">
                        <input type="text" hidden class="form-control" placeholder="Search..." autocomplete="off"
                            id="search-options" value="">
                        <span class="mdi mdi-magnify search-widget-icon"></span>
                        <span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                            id="search-close-options"></span>
                    </div>
                    <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                        <ngx-simplebar style="max-height: 320px;">
                            <!-- item-->
                            <div class="dropdown-header">
                                <h6 class="text-overflow text-muted mb-0 text-uppercase">Recent Searches</h6>
                            </div>

                            <div class="dropdown-item bg-transparent text-wrap">
                                <a href="javascript:void(0);" class="btn btn-soft-secondary btn-sm btn-rounded">how to
                                    setup <i class="mdi mdi-magnify ms-1"></i></a>
                                <a href="javascript:void(0);" class="btn btn-soft-secondary btn-sm btn-rounded">buttons
                                    <i class="mdi mdi-magnify ms-1"></i></a>
                            </div>
                            <!-- item-->
                            <div class="dropdown-header mt-2">
                                <h6 class="text-overflow text-muted mb-1 text-uppercase">Pages</h6>
                            </div>

                            <!-- item-->
                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <i class="ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>
                                <span>Analytics Dashboard</span>
                            </a>

                            <!-- item-->
                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <i class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
                                <span>Help Center</span>
                            </a>

                            <!-- item-->
                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <i class="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
                                <span>My account settings</span>
                            </a>

                            <!-- item-->
                            <div class="dropdown-header mt-2">
                                <h6 class="text-overflow text-muted mb-2 text-uppercase">Members</h6>
                            </div>

                            <div class="notification-list">
                                <!-- item -->
                                <a href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2">
                                    <img src="assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs"
                                        alt="user-pic">
                                    <div class="flex-1">
                                        <h6 class="m-0">Angela Bernier</h6>
                                        <span class="fs-11 mb-0 text-muted">Manager</span>
                                    </div>
                                </a>
                                <!-- item -->
                                <a href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2">
                                    <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs"
                                        alt="user-pic">
                                    <div class="flex-1">
                                        <h6 class="m-0">David Grasso</h6>
                                        <span class="fs-11 mb-0 text-muted">Web Designer</span>
                                    </div>
                                </a>
                                <!-- item -->
                                <a href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2">
                                    <img src="assets/images/users/avatar-5.jpg" class="me-3 rounded-circle avatar-xs"
                                        alt="user-pic">
                                    <div class="flex-1">
                                        <h6 class="m-0">Mike Bunch</h6>
                                        <span class="fs-11 mb-0 text-muted">React Developer</span>
                                    </div>
                                </a>
                            </div>
                        </ngx-simplebar>

                        <div class="text-center pt-3 pb-1">
                            <a href="pages/search-results" class="btn btn-primary btn-sm">View All Results <i
                                    class="ri-arrow-right-line ms-1"></i></a>
                        </div>
                    </div>
                </form>
            </div>

            <div class="d-flex align-items-center">

                <div class="dropdown d-md-none topbar-head-dropdown header-item" ngbDropdown>
                    <button hidden type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" ngbDropdownToggle>
                        <i class="bx bx-search fs-22"></i>
                    </button>
                    <div hidden class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                        aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>
                        <form class="p-3">
                            <div class="form-group m-0">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search ..."
                                        aria-label="Recipient's username">
                                    <button class="btn btn-primary" type="submit"><i
                                            class="mdi mdi-magnify"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div hidden class="dropdown ms-1 topbar-head-dropdown header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="20"
                            class="rounded">
                        <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="20"
                            class="rounded">
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>

                        <!-- item-->
                        <a href="javascript:void(0);" class="dropdown-item notify-item language py-2" data-lang="eng"
                            *ngFor="let item of listLang" (click)="setLanguage(item.text, item.lang, item.flag)"
                            [ngClass]="{'active': cookieValue === item.lang}">
                            <img src="{{item.flag}}" alt="user-image" class="me-2 rounded" height="18"> <span
                                class="align-middle">{{item.text}}</span>
                        </a>
                    </div>
                </div>

                <div hidden class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class='bx bx-category-alt fs-22'></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" ngbDropdownMenu>
                        <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fw-semibold fs-15"> Web Apps </h6>
                                </div>
                                <div class="col-auto">
                                    <a href="javascript:void(0);" class="btn btn-sm btn-soft-info"> View All Apps
                                        <i class="ri-arrow-right-s-line align-middle"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="p-2">
                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/github.png" alt="Github">
                                        <span>GitHub</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                                        <span>Bitbucket</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/dribbble.png" alt="dribbble">
                                        <span>Dribbble</span>
                                    </a>
                                </div>
                            </div>

                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/dropbox.png" alt="dropbox">
                                        <span>Dropbox</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                                        <span>Mail Chimp</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/slack.png" alt="slack">
                                        <span>Slack</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div hidden class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        id="page-header-cart-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" ngbDropdownToggle>
                        <i class='bx bx-shopping-bag fs-22'></i>
                        <span
                            class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-info">7<span
                                class="visually-hidden">unread messages</span></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-xl dropdown-menu-end p-0"
                        aria-labelledby="page-header-cart-dropdown" ngbDropdownMenu>
                        <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fs-16 fw-semibold"> My Cart</h6>
                                </div>
                                <div class="col-auto">
                                    <span class="badge badge-soft-warning fs-13"> 7 items</span>
                                </div>
                            </div>
                        </div>
                        <ngx-simplebar style="max-height: 300px;">
                            <div class="p-2">
                                <div class="d-block dropdown-item text-wrap px-3 py-2">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/products/img-1.png"
                                            class="me-3 rounded-circle avatar-sm p-2 bg-light" alt="user-pic">
                                        <div class="flex-1">
                                            <h6 class="mt-0 mb-1 fs-14">
                                                <a href="ecommerce/product-detail/1" class="text-reset">Branded
                                                    T-Shirts</a>
                                            </h6>
                                            <p class="mb-0 fs-12 text-muted">
                                                Quantity: <span>10 x $32</span>
                                            </p>
                                        </div>
                                        <div class="px-2">
                                            <h5 class="m-0 fw-normal">$320</h5>
                                        </div>
                                        <div class="ps-2">
                                            <button type="button" class="btn btn-icon btn-sm btn-ghost-secondary"><i
                                                    class="ri-close-fill fs-16"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-block dropdown-item text-wrap px-3 py-2">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/products/img-2.png"
                                            class="me-3 rounded-circle avatar-sm p-2 bg-light" alt="user-pic">
                                        <div class="flex-1">
                                            <h6 class="mt-0 mb-1 fs-14">
                                                <a href="ecommerce/product-detail/1" class="text-reset">Bentwood
                                                    Chair</a>
                                            </h6>
                                            <p class="mb-0 fs-12 text-muted">
                                                Quantity: <span>5 x $18</span>
                                            </p>
                                        </div>
                                        <div class="px-2">
                                            <h5 class="m-0 fw-normal">$89</h5>
                                        </div>
                                        <div class="ps-2">
                                            <button type="button" class="btn btn-icon btn-sm btn-ghost-secondary"><i
                                                    class="ri-close-fill fs-16"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-block dropdown-item text-wrap px-3 py-2">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/products/img-3.png"
                                            class="me-3 rounded-circle avatar-sm p-2 bg-light" alt="user-pic">
                                        <div class="flex-1">
                                            <h6 class="mt-0 mb-1 fs-14">
                                                <a href="ecommerce/product-detail/1" class="text-reset">
                                                    Borosil Paper Cup</a>
                                            </h6>
                                            <p class="mb-0 fs-12 text-muted">
                                                Quantity: <span>3 x $250</span>
                                            </p>
                                        </div>
                                        <div class="px-2">
                                            <h5 class="m-0 fw-normal">$750</h5>
                                        </div>
                                        <div class="ps-2">
                                            <button type="button" class="btn btn-icon btn-sm btn-ghost-secondary"><i
                                                    class="ri-close-fill fs-16"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-block dropdown-item text-wrap px-3 py-2">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/products/img-6.png"
                                            class="me-3 rounded-circle avatar-sm p-2 bg-light" alt="user-pic">
                                        <div class="flex-1">
                                            <h6 class="mt-0 mb-1 fs-14">
                                                <a href="ecommerce/product-detail/1" class="text-reset">Gray
                                                    Styled T-Shirt</a>
                                            </h6>
                                            <p class="mb-0 fs-12 text-muted">
                                                Quantity: <span>1 x $1250</span>
                                            </p>
                                        </div>
                                        <div class="px-2">
                                            <h5 class="m-0 fw-normal">$1250</h5>
                                        </div>
                                        <div class="ps-2">
                                            <button type="button" class="btn btn-icon btn-sm btn-ghost-secondary"><i
                                                    class="ri-close-fill fs-16"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-block dropdown-item text-wrap px-3 py-2">
                                    <div class="d-flex align-items-center">
                                        <img src="assets/images/products/img-5.png"
                                            class="me-3 rounded-circle avatar-sm p-2 bg-light" alt="user-pic">
                                        <div class="flex-1">
                                            <h6 class="mt-0 mb-1 fs-14">
                                                <a href="ecommerce/product-detail/1" class="text-reset">Stillbird
                                                    Helmet</a>
                                            </h6>
                                            <p class="mb-0 fs-12 text-muted">
                                                Quantity: <span>2 x $495</span>
                                            </p>
                                        </div>
                                        <div class="px-2">
                                            <h5 class="m-0 fw-normal">$990</h5>
                                        </div>
                                        <div class="ps-2">
                                            <button type="button" class="btn btn-icon btn-sm btn-ghost-secondary"><i
                                                    class="ri-close-fill fs-16"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-simplebar>
                        <div class="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border d-grid">
                            <div class="d-flex justify-content-between align-items-center pb-3">
                                <h5 class="m-0 text-muted">Total:</h5>
                                <div class="px-2">
                                    <h5 class="m-0">$1258.58</h5>
                                </div>
                            </div>

                            <a href="ecommerce/checkout" class="btn btn-success text-center">
                                Checkout
                            </a>
                        </div>
                    </div>
                </div>
                <div class="ms-1 header-item d-none d-sm-flex">
                    <button (click)="news()" type="button"
                        class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                        <i class='ri-information-line fs-22'></i>
                    </button>
                </div>
                <div class="ms-1 header-item d-none d-sm-flex">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        data-toggle="fullscreen" (click)="fullscreen()">
                        <i class='bx bx-fullscreen fs-22'></i>
                    </button>
                </div>
                <div class="ms-1 header-item d-none d-sm-flex">
                    <button type="button"
                        class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                        <i class='bx bx-moon fs-22' (click)="changeMode('dark')"></i>
                        <i class='bx bx-sun fs-22' (click)="changeMode('light')"></i>
                    </button>
                </div>

                <div class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown>
                    <button (click)="getNotifications()" type="button"
                        class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" ngbDropdownToggle>
                        <i class='bx bx-bell fs-22'></i>
                        <span *ngIf="countNotification!='0'"
                            class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{{countNotification}}<span
                                class="visually-hidden">unread messages</span></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                        aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>

                        <div class="dropdown-head bg-primary bg-pattern rounded-top">
                            <div class="p-3">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="m-0 fs-16 fw-semibold text-white"> Notificações </h6>
                                    </div>
                                    <div class="col-auto dropdown-tabs">
                                        <span class="badge badge-soft-light fs-13"> {{countNotification}} Novas</span>
                                    </div>
                                </div>
                            </div>

                            <div class="px-2 pt-2">
                                <ul ngbNav #nav="ngbNav" [activeId]="1"
                                    class="nav nav-tabs dropdown-tabs nav-tabs-custom" id="notificationItemsTab">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink>
                                            Novas
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab"
                                                role="tabpanel">
                                                <ngx-simplebar style="max-height: 300px;">
                                                    <div *ngIf="notifications && notifications!=0" class="pe-2">
                                                        <div *ngFor="let notification of notifications"
                                                            class="text-reset notification-item d-block dropdown-item position-relative">
                                                            <div class="d-flex">
                                                                <div class="flex-1">
                                                                    <a class="stretched-link">
                                                                        <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                                                            {{notification.title}}</h6>
                                                                    </a>
                                                                    <div class="fs-13 text-muted">
                                                                        <p class="mb-1">{{notification.message}}</p>
                                                                    </div>
                                                                    <p
                                                                        class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span><i
                                                                                class="mdi mdi-clock-outline"></i> {{notification.created_at.replace('
                                                                            ','T') |
                                                                            date:
                                                                            'dd/MM/yyyy HH:mm'}}</span>
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="notification.url || notification.function" class="px-2 fs-15">
                                                                    <button style="z-index: 1000;" type="button"
                                                                        (click)="showNotification(notification)"
                                                                        class="btn btn-soft-success waves-effect waves-light">Ver</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div hidden class="my-3 text-center">
                                                            <button type="button"
                                                                class="btn btn-soft-success waves-effect waves-light">Ver
                                                                todas notificações <i
                                                                    class="ri-arrow-right-line align-middle"></i></button>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="notifications == 0" class="pe-2 mt-5 mb-5 text-center">
                                                        <h6>Nenhuma notificação</h6>
                                                    </div>
                                                    <div *ngIf="!notifications" class="pe-2 mt-5 mb-5 text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                </ngx-simplebar>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2">
                                        <a (click)="getAllNotifications()" ngbNavLink>
                                            Todas
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab"
                                                role="tabpanel">
                                                <ngx-simplebar style="max-height: 300px;">
                                                    <div *ngIf="allNotifications.data && allNotifications.data!=0"
                                                        class="pe-2">
                                                        <div *ngFor="let notification of allNotifications.data"
                                                            class="text-reset notification-item d-block dropdown-item position-relative">
                                                            <div class="d-flex">
                                                                <div class="flex-1">
                                                                    <a class="stretched-link">
                                                                        <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                                                            {{notification.title}}</h6>
                                                                    </a>
                                                                    <div class="fs-13 text-muted">
                                                                        <p class="mb-1">{{notification.message}}</p>
                                                                    </div>
                                                                    <p
                                                                        class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span><i
                                                                                class="mdi mdi-clock-outline"></i> {{notification.created_at.replace('
                                                                            ','T') |
                                                                            date:
                                                                            'dd/MM/yyyy HH:mm'}}</span>
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="notification.url || notification.function" class="px-2 fs-15">
                                                                    <button style="z-index: 1000;" type="button"
                                                                        (click)="showNotification(notification)"
                                                                        class="btn btn-soft-success waves-effect waves-light">Ver</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="my-3 text-center">
                                                            <button
                                                                *ngIf="allNotifications.pages != allNotifications.page && !allNotifications.incrementing"
                                                                (click)="getAllNotifications(true)" type="button"
                                                                class="btn btn-soft-success waves-effect waves-light">Ver
                                                                mais <i
                                                                    class="ri-arrow-right-line align-middle"></i></button>
                                                            <div *ngIf="allNotifications.incrementing" class="spinner-border" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="allNotifications.data == 0"
                                                        class="pe-2 mt-5 mb-5 text-center">
                                                        <h6>Nenhuma notificação</h6>
                                                    </div>
                                                    <div *ngIf="!allNotifications.data" class="pe-2 mt-5 mb-5 text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                </ngx-simplebar>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li hidden [ngbNavItem]="3">
                                        <a ngbNavLink>
                                            Alerts
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="tab-pane p-4" id="alerts-tab" role="tabpanel"
                                                aria-labelledby="alerts-tab">
                                                <div class="w-25 w-sm-50 pt-3 mx-auto">
                                                    <img src="assets/images/svg/bell.svg" class="img-fluid"
                                                        alt="user-pic">
                                                </div>
                                                <div class="text-center pb-5 mt-2">
                                                    <h6 class="fs-18 fw-semibold lh-base">Hey! You have no any
                                                        notifications </h6>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div class="tab-content" id="notificationItemsTabContent">
                            <div class="tab-content text-muted">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
                    <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <span class="d-flex align-items-center">
                            <div class="profile-user">
                                <img *ngIf="!user.profile_photo" class="rounded-circle header-profile-user"
                                    src="assets/images/user-photo-null.png" alt="Header Avatar">
                                <img *ngIf="user.profile_photo" style="border: none !important;"
                                    class="rounded-circle header-profile-user user-profile-image"
                                    src="{{user.profile_photo}}" alt="Header Avatar">

                            </div>
                            <span class="text-start ms-xl-2">
                                <span *ngIf="fishName"
                                    class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{fishName}}</span>
                                <span
                                    class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{user.profile_name}}</span>
                            </span>
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <!-- item-->
                        <h6 *ngIf="fishName" class="dropdown-header">Bem vindo(a) {{fishName}}!</h6>
                        <a class="dropdown-item" routerLink="pages/my-profile"><i
                                class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Perfil</span></a>
                        <a hidden class="dropdown-item" href="apps/chat"><i
                                class="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Messages</span></a>
                        <a hidden class="dropdown-item" href="tasks/kanban"><i
                                class="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Taskboard</span></a>
                        <a hidden class="dropdown-item" href="pages/faqs"><i
                                class="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Help</span></a>
                        <div class="dropdown-divider"></div>
                        <a hidden class="dropdown-item" href="pages/profile"><i
                                class="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Balance : <b>$5971.67</b></span></a>
                        <a hidden class="dropdown-item" href="pages/profile"><span
                                class="badge bg-soft-success text-success mt-1 float-end">New</span><i
                                class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Settings</span></a>
                        <a hidden class="dropdown-item" href="auth/lockscreen/basic"><i
                                class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Lock screen</span></a>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="loggof()"><i
                                class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle" data-key="t-logout">Sair</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>