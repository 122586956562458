import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as Enums from 'src/app/enums';

@Injectable({
  providedIn: 'root'
})
export class AtendeChangeLogsService {
  private headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    api: 'true',
  };

  constructor(
    private http: HttpClient,
    public httpParams: HttpParams
  ) { }

  /**
   * Lista change logs
   * @param data = {
        reverse: '0|1,
        order_column: string,
        page: number,
        rows: number,
        title: string,
        status: '0|1'
    };
   */
  list(data: {}) {
    var headers = new HttpHeaders(this.headers);
    return this.http.post(Enums.VARS.API_BASE_URL + 'guard/atendeChangeLogs/list', JSON.stringify(data), { headers: headers });
  }

  /**
   * Insere novo changelog
   * @param data = {
        title: string - required,
        description: string - required,
        date: datetime - optional,
        status: 1|0 - default 1
    };
   */
  store(data: {}) {
    var headers = new HttpHeaders(this.headers);
    return this.http.post(Enums.VARS.API_BASE_URL + 'guard/atendeChangeLogs/store', JSON.stringify(data), { headers: headers });
  }

  /**
   * Atualiza change log
   * @param id :number 
   * @param data = {
        title: string - required,
        description: string - required,
        subtitle: string - required,
        status: 0|1 - default - 1
    };
   */
  update(id:number, data: {}){
    var headers = new HttpHeaders(this.headers);
    return this.http.put(Enums.VARS.API_BASE_URL + 'guard/atendeChangeLogs/update/'+id, JSON.stringify(data), { headers: headers });
  }

  /**
   * retorna historicos do change log
   * @param status 
   * @param atende_change_log_id 
   * @param atende_change_log_history_id 
   * @param page 
   * @param rows 
   */
  getHistories(
    status:number|string = '',
    atende_change_log_id:number = 0,
    atende_change_log_history_id: number = 0,
    page: number = 1,
    rows: number = 10
  ) {
    var headers = new HttpHeaders(this.headers);
    const patch = '?status=' + status +
    '&atende_change_log_id=' + atende_change_log_id +
    '&atende_change_log_history_id=' + atende_change_log_history_id +
    '&rows=' + rows +
    '&page=' + page;
    return this.http.get(Enums.VARS.API_BASE_URL + 'guard/atendeChangeLogs/update'+patch,  { headers: headers })
  }
}
