import { ChartHelper } from "src/app/standalone components/helpers/chart-helper";
import { BarChartData } from "../../types/bar-chart-data";
import { ChartDataSet } from "../../types/chart-data-set";
import { IChart } from "../interfaces/i-chart";

export class BarChart implements IChart {

    constructor() { }
    getChartOptions() {
        let options = {
            responsive: true
        }
        return options;
    }

    colors!: string[];

    setColors(colors: string[]): void {
        this.colors = colors;
    }

    /**
     * Gera um gráfico de barras
     * @param data dados do gráfico (Ex.: [{ key: 'Sales', value: 20 }, { key: 'Revenue', value: 10 }])
     * @returns BarChartData
     */
    public getChartData(data: ChartDataSet[]): BarChartData {

        const groupedData = ChartHelper.groupDataByLabel(data);
        const datasets: any = this.buildDataSet(groupedData);
        const barChartData: BarChartData = {
            datasets: datasets
        };

        return barChartData;
    }

    /**
     * 
     * @param groupedData Record<string, ChartDataSet[]
     * This parameter is basically the ChartData grouped by its labels
     * @returns 
     */
    private buildDataSet(groupedData: any): any {
        var dataset = Object.keys(groupedData).map(label =>
        ({
            label: label,
            data: groupedData[label].data,
            parsing: {
                xAxisKey: 'key',
                yAxisKey: 'value',
            },
            backgroundColor: ChartHelper.empty(groupedData[label].colors) ? this.colors: groupedData[label].colors
        })
        );

        return dataset;
    }

}