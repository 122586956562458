import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationsService } from './core/services/notifications.service';
import { UserProfileService } from './core/services/user.service';
import { BastaniLoadingComponent } from './components/bastani-loading/bastani-loading.component';
import { LoadingService } from './core/services/loading.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'velzon';
    loading: string|null = null;
    private user: any;
    constructor(
        private router: Router,
        private usr: UserProfileService,
        private notificationsService: NotificationsService,
        private loadingService : LoadingService
    ) {

    }

    async ngOnInit() {
        const user = await this.usr.getUser();
        if (user) this.usr.checkSession();
        this.getNotificationsRows(this.usr, this.notificationsService);
        this.checkingNotifications();
        this.loadingService.activateLoading.subscribe((str: string|null) => {
            this.loading = str;
        });
    }

    // verifica noificações de 10 em 10 min
    async checkingNotifications() {
        setInterval(this.getNotificationsRows, 600000, this.usr, this.notificationsService);
    }



    async getNotificationsRows(userService: UserProfileService, notificationsService: NotificationsService) {
        const user:any = await userService.getUser();
        if (user) {
            if (typeof user.token != "undefined" && typeof user.permissions != "undefined") {
                notificationsService.counRows().subscribe(async (response: any) => {
                    if (response.status) {
                        await notificationsService.setNotificationStorage(response.data);
                        notificationsService.updateObservableNotification(true);
                    }
                });
            }
        }
    }
}
