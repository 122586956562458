<!-- ========== App Menu ========== -->

<div class="app-menu navbar-menu">

  <!-- LOGO -->
  <div class="navbar-brand-box pt-3 pb-3">
    <!-- Dark Logo-->
    <!-- Dark Logo-->

    <a routerLink="/" class="logo logo-dark">
      <span class="logo-sm">
        <img src="assets/images/logo-apvs-topo-2.png" alt="" height="30">
      </span>
      <span class="logo-lg">
        <img src="assets/images/time-system-apvs-brasil.png" alt="" height="60">
      </span>
    </a>
    <!-- Light Logo-->
    <a routerLink="/" class="logo logo-light">
      <span class="logo-sm">
        <img src="assets/images/logo-apvs-topo-2.png" alt="" height="30">
      </span>
      <span class="logo-lg">
        <img src="assets/images/time-system-apvs-brasil.png" alt="" height="60">
      </span>
    </a>
    <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
      <i class="ri-record-circle-line"></i>
    </button>
  </div>


  <div id="scrollbar" *ngIf="menuItems && user.permissions">
    <div class="container-fluid">

      <div id="two-column-menu">

        <ul class="twocolumn-iconview" data-simplebar="init">
          <div class="simplebar-wrapper" style="margin: 0px;">
            <div class="simplebar-mask">
              <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content"
                  style="height: 100%; overflow: hidden scroll;">
                  <div class="simplebar-content" style="padding: 0px;">
                    <a href="javascript:void(0);" class="logo">
                      <img src="assets/images/logo-apvs-topo-2.png" alt="" height="30">
                    </a>
                    <ng-container *ngFor="let item of menuItems">
                      <ng-container *ngIf="verifyItem(item.permission) || !item.permission">
                        <li *ngIf="!item.isTitle && !item.isLayout">
                          <a *ngIf="hasItems(item)" href="javascript:void(0);" [attr.subitems]="item.collapseid"
                            class="nav-icon" (click)="toggleItem($event)">
                            <i class="{{ item.icon }}" class=""></i>
                            <span data-key="t-dashboards" class="d-none">{{item.label  }}</span>
                          </a>
                          <a *ngIf="!hasItems(item)" [routerLink]="item.link" [attr.subitems]="item.collapseid"
                            class="nav-icon">
                            <i class="{{ item.icon }}" class=""></i>
                            <span data-key="t-widget" class="d-none">{{item.label  }}</span>
                          </a>
                        </li>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>

      <ul class="navbar-nav" id="navbar-nav" data-simplebar="init">
        <div class="simplebar-wrapper" style="margin: 0px;">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer"></div>
          </div>
          <div class="simplebar-mask">
            <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
              <div class="dv_btn_close ion-hide-md-up" >
                <button type="button" style="position: relative !important;"
                  class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger btn_close" (click)="changeMenu()">
  
                  <i class="ri-close-line"></i>
  
                </button>
              </div>
              <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content"
                style="height: 100%; overflow: hidden;">
                <div class="simplebar-content" style="padding: 0px;">
                  <li class="menu-title"><span data-key="t-menu">Menu</span></li>
                  <li class="nav-item" *ngFor="let item of menuItems">
                    <ng-container *ngIf="verifyItem(item.permission) || !item.permission">
                      <ng-container>

                        <div class="collapse menu-dropdown" id="{{item.collapseid}}"
                          [attr.data-name]="item.label  " aria-expanded="true">
                          <ul *ngIf="hasItems(item)" class="nav nav-sm flex-column" aria-expanded="false">
                            <li *ngFor="let subitem of item.subItems" class="nav-item">
                              <ng-container *ngIf="verifyItem(subitem.permission) || !subitem.permission">
                                <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="nav-link"
                                  [attr.data-parent]="subitem.parentId" (click)="updateActive($event)">
                                  {{ subitem.label  }}
                                  <span class="badge badge-pill {{subitem.badge.variant}}"
                                    *ngIf="subitem.badge">{{subitem.badge.text
                                     }}</span>

                                </a>
                                <a *ngIf="hasItems(subitem)" class="nav-link ecomm" data-bs-toggle="collapse"
                                  [attr.aria-expanded]="false" href="javascript:void(0);"
                                  [attr.data-parent]="subitem.parentId" (click)="toggleSubItem($event)">
                                  {{ subitem.label  }}
                                  <span class="badge badge-pill {{subitem.badge.variant}}"
                                    *ngIf="subitem.badge">{{subitem.badge.text
                                     }}</span>
                                </a>

                                <div class="collapse menu-dropdown sub-menu" *ngIf="hasItems(subitem)"
                                  id="{{item.collapseid}}">
                                  <ul class="nav nav-sm flex-column">
                                    <li *ngFor="let subSubitem of subitem.subItems" class="nav-item">
                                      <ng-container *ngIf="verifyItem(subSubitem.permission) || !subSubitem.permission">

                                        <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" [queryParams]="subSubitem.params ? subSubitem.params : {}"
                                          class="nav-link" (click)="updateActive($event)" *ngIf="!hasItems(subSubitem)">
                                          {{ subSubitem.label   }}
                                          <span class="badge badge-pill {{subSubitem.badge.variant}}"
                                            *ngIf="subSubitem.badge">{{subSubitem.badge.text
                                             }}</span>
                                        </a>

                                        <a *ngIf="hasItems(subSubitem)" class="nav-link" href="javascript:void(0);"
                                          [attr.data-parent]="subSubitem.parentId" data-bs-toggle="collapse"
                                          aria-expanded="false" (click)="toggleExtraSubItem($event)">
                                          {{ subSubitem.label  }}
                                          <span class="badge badge-pill {{subSubitem.badge.variant}}"
                                            *ngIf="subSubitem.badge">{{subSubitem.badge.text
                                             }}</span>
                                        </a>

                                        <div class="collapse menu-dropdown extra-sub-menu" *ngIf="hasItems(subSubitem)">
                                          <ul *ngIf="hasItems(subSubitem)" class="nav nav-sm flex-column"
                                            aria-expanded="false">
                                            <li *ngFor="let subSubitem1 of subSubitem.subItems" class="nav-item">

                                              <a [attr.data-parent]="subSubitem1.parentId"
                                                [routerLink]="subSubitem1.link" routerLinkActive="active" [queryParams]="subSubitem1.params ? subSubitem1.params : {}"
                                                class="nav-link" (click)="updateActive($event)" *ngIf="!hasItems(subSubitem1)">
                                                {{ subSubitem1.label}}
                                                <span class="badge badge-pill {{subSubitem1.badge.variant}}"
                                                *ngIf="subSubitem1.badge">{{subSubitem1.badge.text
                                                }}</span>
                                              </a>

                                              <a *ngIf="hasItems(subSubitem1)" class="nav-link" href="javascript:void(0);"
                                                [attr.data-parent]="subSubitem1.parentId" data-bs-toggle="collapse"
                                                aria-expanded="false" (click)="toggleExtraSubItem($event, '-2')">
                                                {{ subSubitem1.label  }}
                                                <span class="badge badge-pill {{subSubitem1.badge.variant}}"
                                                  *ngIf="subSubitem1.badge">{{subSubitem1.badge.text
                                                  }}</span>
                                              </a>

                                              <div class="collapse menu-dropdown-2 extra-sub-menu-2" *ngIf="hasItems(subSubitem1)">
                                                <ul *ngIf="hasItems(subSubitem1)" class="nav nav-sm flex-column"
                                                  aria-expanded="false">
                                                  <li *ngFor="let subSubitem2 of subSubitem1.subItems" class="nav-item">
                                                      <a [attr.data-parent]="subSubitem2.parentId"
                                                      [routerLink]="subSubitem2.link" routerLinkActive="active" [queryParams]="subSubitem2.params ? subSubitem2.params : {}"
                                                      class="nav-link" (click)="updateActive($event, true)">
                                                      {{ subSubitem2.label}}
                                                      
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>

                                            </li>
                                          </ul>
                                        </div>
                                      </ng-container>
                                    </li>
                                  </ul>
                                </div>
                              </ng-container>
                            </li>
                          </ul>
                        </div>
                      </ng-container>
                    </ng-container>
                  </li> <!-- end Dashboard Menu -->
                </div>
              </div>
            </div>
          </div>
          <div class="simplebar-placeholder" style="width: auto; height: 183px;"></div>
        </div>
      </ul>
    </div>
    <!-- Sidebar -->
  </div>

  <div class="sidebar-background"></div>
</div>
<!-- Left Sidebar End -->
<!-- Vertical Overlay-->
<div class="vertical-overlay"></div>