import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApvsAtendeComponent } from './apvs-atende/apvs-atende.component';

const routes: Routes = [
    {
        path: "",
        component: ApvsAtendeComponent
    },
    {
      path: '', loadChildren: () => import('./apvs-atende/apvs-atende.module').then(m => m.ApvsAtendeModule)
    },
    { path: 'ui', loadChildren: () => import('./ui/ui.module').then(m => m.UiModule) },
    {
      path: 'pages', loadChildren: () => import('./extrapages/extraspages.module').then(m => m.ExtraspagesModule)
    },
    {
      path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }

