<div class="my_content">
    <h4 class="mb-3" *ngIf="!permissions.analyst_dashboard">Bem-vindo(a) ao Atende!</h4>
    <ng-container *ngIf="permissions.analyst_dashboard">
        <div class="container-fluid mt-2">
            <div class="row">
                <div class="col-sm-6 col-md-3">
                    <label class="form-label">Data Inicial</label>
                    <input class="form-control" type="date" [(ngModel)]="startDate"
                        [value]="startDate | date:'yyyy-MM-dd'">
                </div>
                <div class="col-sm-6 col-md-3">
                    <label class="form-label">Data Final</label>
                    <input class="form-control" type="date" [(ngModel)]="endDate">
                </div>
                <div class="col-12 col-md-1 d-flex align-items-end">
                    <button class="btn btn-primary w-100" (click)="setCharts()">Buscar</button>
                </div>
            </div>
        </div>

        <hr class="my-2">

        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': page == 1}" (click)="setPage(1)">Estoque</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': page == 2}" (click)="setPage(2)">Produtividade</a>
            </li>
        </ul>

        <!-- DASHBOARDS DE ESTOQUE -->
        <ng-container *ngIf="page == 1">
            <ng-container
                *ngIf="loadedData.statusData && loadedData.auctionData && loadedData.typeData && loadedData.monthData">
                <div class="row">
                    <div class="card bg-light mb-2">
                        <div class="card-body w-100">
                            <div class="row d-flex flex-row justify-content-around align-items-center">
                                <div class="col-md-5">
                                    <app-chart [dataSet]="statusDataSet" [colors]="['#192753']" type="bar"
                                        variation="horizontal"></app-chart>
                                </div>
                                <div class="col-md-3">
                                    <app-chart [dataSet]="auctionDataSet"
                                        [colors]="['#189918', '#8fdb00', '#f3ea38', '#d10a32', '#192753']"
                                        type="doughnut"></app-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="card bg-light">
                        <div class="card-body">
                            <div class="row d-flex flex-row justify-content-around align-items-center">
                                <div class="col-md-2">
                                    <app-chart [dataSet]="typeDataSet" [colors]="['#192753']" type="bar"></app-chart>
                                </div>
                                <div class="col-md-5">
                                    <app-chart [dataSet]="monthDataSet" [colors]="['#192753']" type="line"></app-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container
                *ngIf="!loadedData.statusData || !loadedData.auctionData || !loadedData.typeData || !loadedData.monthData">
                <div class="loader">
                    <div style="text-align: center;">
                        <span class="loader-spiner"></span>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <!-- DASHBOARDS DE PRODUTIVIDADE -->
        <ng-container *ngIf="page == 2">
            <ng-container *ngIf="loadedData.analysisTimeData && loadedData.averageAnalysisTimeData">
                <div class="card bg-light mb-2">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card bg-light">
                                    <div class="card-body">
                                        <h1 class="text-center">Tempo médio</h1>
                                        <h2 class="text-center" style="color: #192753">{{averageTime}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex flex-row justify-content-around align-items-center">
                            <div class="col-md-6">
                                <app-chart [dataSet]="analysisTimeDataSet" type="line"></app-chart>
                            </div>
                            <div class="col-md-6">
                                <app-chart [dataSet]="averageAnalysisTimeDataSet" type="line"
                                    [colors]="['#192753']"></app-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loadedData.analysisTimeData || !loadedData.averageAnalysisTimeData">
                <div class="loader">
                    <div style="text-align: center;">
                        <span class="loader-spiner"></span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>