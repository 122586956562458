import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { ServicesComponent } from "./landing/index/services/services.component";
import { CollectionComponent } from "./landing/index/collection/collection.component";
import { CtaComponent } from "./landing/index/cta/cta.component";
import { DesignedComponent } from "./landing/index/designed/designed.component";
import { PlanComponent } from "./landing/index/plan/plan.component";
import { FaqsComponent } from "./landing/index/faqs/faqs.component";
import { WorkProcessComponent } from "./landing/index/work-process/work-process.component";
import { TeamComponent } from "./landing/index/team/team.component";
import { ContactComponent } from "./landing/index/contact/contact.component";
import { FooterComponent } from "./landing/index/footer/footer.component";
import { ScrollspyDirective } from "./scrollspy.directive";

// NFT Landing
import { MarketPlaceComponent } from "./landing/nft/market-place/market-place.component";
import { WalletComponent } from "./landing/nft/wallet/wallet.component";
import { FeaturesComponent } from "./landing/nft/features/features.component";
import { DiscoverComponent } from "./landing/nft/discover/discover.component";
import { TopCreatorComponent } from "./landing/nft/top-creator/top-creator.component";

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    ServicesComponent,
    CollectionComponent,
    CtaComponent,
    DesignedComponent,
    PlanComponent,
    FaqsComponent,
    WorkProcessComponent,
    TeamComponent,
    ContactComponent,
    FooterComponent,
    ScrollspyDirective,
    MarketPlaceComponent,
    WalletComponent,
    FeaturesComponent,
    DiscoverComponent,
    TopCreatorComponent,
  ],
  imports: [CommonModule, NgbModule],
  exports: [
    BreadcrumbsComponent,
    ServicesComponent,
    CollectionComponent,
    CtaComponent,
    DesignedComponent,
    PlanComponent,
    FaqsComponent,
    WorkProcessComponent,
    TeamComponent,
    ContactComponent,
    FooterComponent,
    ScrollspyDirective,
    WalletComponent,
    MarketPlaceComponent,
    FeaturesComponent,
    DiscoverComponent,
    TopCreatorComponent,
  ],
})
export class SharedModule {}
