import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as Enums from 'src/app/enums';
import { User } from '../models/auth.models';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/core/services/user.service';
import { Observable, BehaviorSubject,from, ObservableInput } from  'rxjs';


@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {

    user!: User;
    currentUserValue: any;

    constructor(
        public router: Router,
        public http: HttpClient,
        public httpParams: HttpParams,
        public usr: UserProfileService
    ) { }

    

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(data: any) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            skip: 'true',
            api: 'true'
        });
        return this.http.post(Enums.VARS.API_BASE_URL + 'auth/login', JSON.stringify(data), { headers: headers });
    }

    resetPassword(data:{}){
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            skip: 'true',
            api: 'true'
        });
        return this.http.post(Enums.VARS.API_BASE_URL + 'auth/login/resetPassword', JSON.stringify(data), { headers: headers });
    }

    getTokenOld(data:any) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            skip: 'true',
            api: 'true',
        });
        return this.http.post(Enums.VARS.API_BASE_URL + 'auth/login/generateToken', JSON.stringify(data), { headers: headers });
    }

    

    /**
     * Performs the token jwt
     * @param token email of user
     */

    getToken(){
        var headers = new HttpHeaders({
            skip: 'true',
            api: 'true',
        });
        const simpleObservable = new Observable((observer) => {
            from(this.usr.getUser()).subscribe((user)=>{
                this.http.post(Enums.VARS.API_BASE_URL + 'auth/login/generateToken', JSON.stringify(user), { headers: headers }).subscribe(async (res: any) => {
                    if (res.status) {
                        observer.next(res);
                        observer.complete()
                    }
                })
            })
        })

        return simpleObservable;
    }

    /**
     * Reset password
     * @param email email
     */
    /*
    resetPassword(email: string) {
        return getFirebaseBackend()!.forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }
    */

}

