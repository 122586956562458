import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor() { }

  activateLoading = new EventEmitter<string>();

  public hide() {
    this.activateLoading.emit('');
  }

  public show(text: string = 'Aguarde...') {
    this.activateLoading.emit(text);
  }
}
