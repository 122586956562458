import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bastani-loading',
  templateUrl: './bastani-loading.component.html',
  styleUrls: ['./bastani-loading.component.scss']
})

export class BastaniLoadingComponent{
  @Input() loading: string|null = null;
}
