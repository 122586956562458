import { Component, OnInit, EventEmitter, Output, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { EventService } from "../../core/services/event.service";
import { UserProfileService } from "src/app/core/services/user.service";

//Logout
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { Router } from "@angular/router";

// Language
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { NotificationsService } from "src/app/core/services/notifications.service";
import { CoreService } from "src/app/core/services/core.service";

import { Preferences} from "@capacitor/preferences";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NewsComponent } from "src/app/pages/apvs-atende/news/news.component";
import { FullscreenService } from "src/app/components/fullscreen-image/fullscreen.service";


@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  element: any;
  mode: string | undefined;
  @Output() mobileMenuButtonClicked = new EventEmitter();
  isProd: boolean = environment.production;

  flagvalue: any;
  valueset: any;
  countryName: any;
  cookieValue: any;
  public user: any;
  public fishName: any;
  public countNotification = "0";
  public notifications: any;
  public allNotifications: any = {
    page: 1,
    data: null,
    pages: 1,
    total: 1,
    incrementing: false,
  };

  constructor(
    @Inject(DOCUMENT) private document: any,
    private eventService: EventService,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    private router: Router,
    private usr: UserProfileService,
    public coreS: CoreService,
    private notificationsService: NotificationsService,
    private modalService: NgbModal,
    private fullScreenService: FullscreenService
  ) {
    this.usr.getObservableUser().subscribe((data) => {
      if (data) {
        this.buildFishName();
        this.usr.updateScreenUser(false);
      }
    });
    this.notificationsService.observableNotification().subscribe((data) => {
      if (data) {
        this.getNotificationsNumber();
      }
    });
  }

  async setMode(data: string) {
    await Preferences.set({
      key: "mode",
      value: data,
    });
  }

  async getMode() {
    let data: any = await Preferences.get({ key: "mode" });
    return data.value;
  }

  async getNotificationsNumber() {
    this.countNotification =
      await this.notificationsService.getNotificationStorage();
    this.notificationsService.updateObservableNotification(false);
  }

  async ngOnInit() {
    await this.buildFishName();

    this.build();
    this.checkMode();

    this.fullScreenService.activateFullScreen.subscribe((str) => {
      str !== "inactive" ? this.changeMenu() : this.closeMenu();
    });
  }

  async checkMode() {
    const mode = await this.getMode();
    if (mode) {
      this.changeMode(mode);
    }
  }

  async buildFishName() {
    this.user = await this.usr.getUser();
    this.fishName = this.user.name.split(" ")[0];
  }

  load(event: any) {}

  news(): void {
    this.modalService.open(NewsComponent, { size: "xl", centered: true });
  }

  async loggof() {
    await this.usr.removeUser();
    this.router.navigate(["/auth/login"], { replaceUrl: true });
  }

  build(): void {
    this.element = document.documentElement;

    // Cookies wise Language set
    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.svg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  changeMenu() {
    document.body.classList.add("twocolumn-panel");
  }

  closeMenu() {
    document.body.classList.remove("twocolumn-panel");
  }

  getNotifications() {
    this.notifications = null;
    this.allNotifications.data = null;
    this.notificationsService.get().subscribe(
      async (response: any) => {
        this.getAllNotifications();
        if (response.status) {
          if (response.data) {
            this.notifications = response.data;
            this.countNotification = "0";
          } else {
            this.notifications = 0;
          }
        } else {
          this.coreS.message(response.message, "danger", "top");
        }
      },
      (error: any) => {
        this.coreS.message("Erro ao buscar notificações.", "danger", "top");
      }
    );
  }

  showNotification(notification: any) {
    const caseFunctions: any = {
      news: this.news.bind(this),
    };
    notification.function
      ? caseFunctions[notification.function]()
      : this.coreS.openLink(notification.url);
  }

  async getAllNotifications(increment = false) {
    this.allNotifications.data = increment ? this.allNotifications.data : null;

    this.allNotifications.incrementing = increment ? true : false;
    this.allNotifications.page = increment ? this.allNotifications.page + 1 : 1;
    this.notificationsService.getAll(this.allNotifications.page).subscribe(
      async (response: any) => {
        if (response.status) {
          if (response.data) {
            this.allNotifications.pages = response.pages;
            this.allNotifications.total = response.total;
            if (!increment) {
              this.allNotifications.data = response.data;
            } else {
              this.allNotifications.incrementing = false;
              let i = this.allNotifications.data.length;
              let allNotifications = this.allNotifications.data;
              await response.data.forEach(function (value: any) {
                allNotifications[i] = value;
                i++;
              });
              this.allNotifications.data = allNotifications;
            }
          } else {
            if (!increment) {
              this.allNotifications = 0;
            } else {
              this.allNotifications.incrementing = false;
            }
          }
        } else {
          this.coreS.message(response.message, "danger", "top");
        }
      },
      (error: any) => {
        this.coreS.message("Erro ao buscar notificações.", "danger", "top");
      }
    );
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Topbar Light-Dark Mode Change
   */
  changeMode(mode: string) {
    this.mode = mode;
    this.eventService.broadcast("changeMode", mode);
    this.setMode(mode);

    switch (mode) {
      case "light":
        document.body.setAttribute("data-layout-mode", "light");
        document.body.setAttribute("data-sidebar", "light");
        break;
      case "dark":
        document.body.setAttribute("data-layout-mode", "dark");
        document.body.setAttribute("data-sidebar", "dark");
        break;
      default:
        document.body.setAttribute("data-layout-mode", "light");
        break;
    }
  }

  /***
   * Language Listing
   */
  listLang = [
    { text: "English", flag: "assets/images/flags/us.svg", lang: "en" },
    { text: "Española", flag: "assets/images/flags/spain.svg", lang: "es" },
    { text: "Deutsche", flag: "assets/images/flags/germany.svg", lang: "de" },
    { text: "Italiana", flag: "assets/images/flags/italy.svg", lang: "it" },
    { text: "русский", flag: "assets/images/flags/russia.svg", lang: "ru" },
    { text: "中国人", flag: "assets/images/flags/china.svg", lang: "ch" },
    { text: "français", flag: "assets/images/flags/french.svg", lang: "fr" },
  ];

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }
}
