import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AtendeChangeLogsService } from 'src/app/core/services/atende-change-logs.service';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
	selector: 'app-news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

	public data: any;
	public pages: number = 0;
	public total: number = 0;
	public params = {
		page: 1,
		rows: 5,
		status: 1
	};
	public incrementing: boolean = false;

	constructor(
		private atendeChangeLogsService: AtendeChangeLogsService,
		public coreS: CoreService,
		public modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.list();
	}

	list(increment: boolean = false): void {
		this.data = increment ? this.data : null;
		this.incrementing = increment;
		this.params.page = increment ? this.params.page + 1 : 1;
		this.atendeChangeLogsService.list(this.params).subscribe(async (response: any) => {
			if (!response.status) {
				this.coreS.message(response.message, 'danger', 'top');
				return;
			}else{
				if (!increment) {
					this.data = response.data;
				} else {
					this.incrementing = false;
					let i = this.data.length;
					let data = this.data;
					await response.data.forEach(function (value: any) {
						data[i] = value;
						i++;
					});
					this.data = data;
				}
				this.pages = response.pages;
				this.total = response.total;
			}
		}, (error: any) => {
			this.coreS.message('Houve erro ao listar.', 'danger', 'top');
		})
	}

}
