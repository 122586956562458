import {
    Component,
    Inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { FullscreenService } from "src/app/components/fullscreen-image/fullscreen.service";
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-bastani-pdf-viewer',
    templateUrl: './bastani-pdf-viewer.component.html',
    styleUrls: ['./bastani-pdf-viewer.component.scss']
})

export class BastaniPdfViewerComponent implements OnInit, OnChanges {
    @Input() src!: string;
    @Input() customString?: string;


    pdf!: string;
    safePdf!: SafeResourceUrl;
    imageZoom: number = 1;
    imageLoaded: boolean = false;
    dragPosition = { x: 0, y: 0 };
    currentSlide: number = 0;
    rotation: number = 0;
    zoom: number = 1;
    showZoom: boolean = false;
    positionChanged: boolean = false;

    OnDragStart() {
        this.positionChanged = true;
    }


    constructor(private fullScreenService: FullscreenService, private sanitizer: DomSanitizer, @Inject(DOCUMENT) private document: Document) { }

    initPdf() {
        this.pdf = this.src;
        this.safePdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    }

    ngOnInit(): void {
        this.initPdf();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.imageLoaded = false;
        this.initPdf();
    }

    zoomIn() {
        this.positionChanged = true;
        this.zoom = this.zoom + 0.1;
        this.changedZoom();
    }
    zoomOut() {
        this.positionChanged = true;
        this.zoom = this.zoom - 0.1;
        this.changedZoom();
    }

    resetImagePosition(): void {
        this.positionChanged = false;
        this.rotation = 0;
        this.zoom = 1;
        this.dragPosition = { x: 0, y: 0 };
    }


    openFullScreenMode() {
        window.open(this.src, "_blank");
    }


    rotateLeft() {
        this.positionChanged = true;
        this.rotation -= 90;
    }

    rotateRight() {
        this.positionChanged = true;
        this.rotation += 90;
    }

    async changedZoom(): Promise<void> {
        this.showZoom = true;
        await new Promise(resolve => setTimeout(resolve, 1500));
        this.showZoom = false;
    }
}

