import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { RouteReuseStrategy } from "@angular/router";
import { AppComponent } from "./app.component";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { LayoutsModule } from "./layouts/layouts.module";
import { PagesModule } from "./pages/pages.module";
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from "@angular/common";
import localePt from '@angular/common/locales/pt';

// Auth
import {
  HttpClientModule,
  HttpParams,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";

import {
  FileTransfer,
  FileTransferObject,
} from "@ionic-native/file-transfer/ngx";
import { File } from "@ionic-native/file/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { TopbarComponent } from "./layouts/topbar/topbar.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CdkDrag } from "@angular/cdk/drag-drop";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { ComponentsModule } from "./components/components.module";

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    IonicModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    LayoutsModule,
    PagesModule,
    NgxExtendedPdfViewerModule,
    NgbModule,
    CdkDrag,
    MatButtonModule,
    NgbCarouselModule,
    MatIconModule,
    ComponentsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'pt' },
    HttpParams,
    FileTransfer,
    FileTransferObject,
    File,
    FileOpener,
    TopbarComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
