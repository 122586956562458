import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgChartsModule } from 'ng2-charts';
import { ChartFactory } from './design-patterns/factory/chart-factory';
import { IChart } from './design-patterns/interfaces/i-chart';
import { ChartDt } from './types/chart-dt';
import { ChartDataSet } from './types/chart-data-set';
import { Chart, ChartOptions, ChartTypeRegistry } from "chart.js";
import { Colors } from "chart.js";

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  imports: [NgChartsModule],
  standalone: true
})
export class ChartComponent implements OnInit, OnChanges {

  constructor(private chartFactory: ChartFactory) { }

  @Input() dataSet!: ChartDataSet[];
  @Input() type!: keyof ChartTypeRegistry;
  @Input() variation: string = '';
  @Input() colors?: any[];

  public chart?: IChart;
  public chartData?: ChartDt;
  public chartOptions?: ChartOptions;

  ngOnInit(): void {
    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initChart();
  }

  initChart() {
    Chart.register(Colors);
    this.chart = this.chartFactory.createChart(this.type, this.variation, this.colors);
    this.chartData = this.chart.getChartData(this.dataSet);
    this.chartOptions = this.chart.getChartOptions(this.dataSet);
  }

}
