import { Component, HostListener, OnInit } from '@angular/core';

import { EventService } from '../core/services/event.service';
import {
  LAYOUT_VERTICAL, LAYOUT_HORIZONTAL, LAYOUT_TWOCOLUMN, LAYOUT_MODE, LAYOUT_WIDTH,
  LAYOUT_POSITION, SIDEBAR_SIZE, SIDEBAR_COLOR, TOPBAR
} from './layout.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

/**
 * Layout Component
 */
export class LayoutComponent implements OnInit {

  layoutType!: string;

  constructor(private eventService: EventService) { }

  //@HostListener('window:resize', ['$event'])
  /*
  public onResize(event: any) {
    if (event.target.innerWidth < 771 && this.layoutType != LAYOUT_VERTICAL) {
      this.layoutType = LAYOUT_VERTICAL;
      this.changeLayout('vertical');
    } else if(event.target.innerWidth >= 771 && this.layoutType != LAYOUT_TWOCOLUMN) {
      this.layoutType = LAYOUT_TWOCOLUMN;
      this.changeLayout('twocolumn');
    }
  }
  */

  ngOnInit(): void {
    this.layoutType = LAYOUT_TWOCOLUMN;

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
    });

  }

  changeLayout(layout: string) {
    this.eventService.broadcast('changeLayout', layout);
  }

  /**
  * Check if the vertical layout is requested
  */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isTwoColumnLayoutRequested() {
    return this.layoutType === LAYOUT_TWOCOLUMN;
  }

}
