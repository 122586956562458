import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";

import { FormsModule } from "@angular/forms";

import { FlatpickrModule } from "angularx-flatpickr";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { SimplebarAngularModule } from "simplebar-angular";
import { ChartComponent } from 'src/app/standalone components/chart/chart.component';

// Load Icons
import { defineElement } from "lord-icon-element";
import lottie from "lottie-web";

// Pages Routing
import { PagesRoutingModule } from "./pages-routing.module";
import { SharedModule } from "../shared/shared.module";
import { WidgetModule } from "../shared/widget/widget.module";
import { ApvsAtendeComponent } from "./apvs-atende/apvs-atende.component";

@NgModule({
  declarations: [ApvsAtendeComponent],
  imports: [
    CommonModule,
    NgbTypeaheadModule,
    FlatpickrModule.forRoot(),
    NgApexchartsModule,
    NgbDropdownModule,
    SimplebarAngularModule,
    PagesRoutingModule,
    SharedModule,
    WidgetModule,
    FormsModule,
    ChartComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
