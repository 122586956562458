<!-- Start Breadcrumbs -->
<app-breadcrumbs hidden title="Novidades"></app-breadcrumbs>
<!-- End Breadcrumbs -->
<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel">Novidades</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
    (click)="modalService.dismissAll()"></button>
</div>
<div class="my_content">
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <div class="timeline" *ngIf="data">
                        <ng-container *ngIf="data.length>0">
                            <div *ngFor="let value of data; let i = index;"
                                class="timeline-item {{i%2==0 ? 'left' : 'right'}}">
                                <i class="icon ri-stack-line"></i>
                                <div class="date">{{value.date|date:'dd/MM/yyyy'}} às {{value.date|date:'HH:mm'}}</div>
                                <div class="content">
                                    <div class="d-flex">
                                        <div class="flex-grow-1 ms-3">
                                            <h5 class="fs-15">{{value.title}}</h5>
                                            <h6 class="fs-13">{{value.subtitle}}</h6>
                                            <p class="text-muted mb-2">{{value.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-3 text-center">
                                <button
                                    *ngIf="pages != params.page && !incrementing"
                                    (click)="list(true)" type="button"
                                    class="btn btn-soft-success waves-effect waves-light">Ver
                                    mais <i
                                        class="ri-arrow-right-line align-middle"></i></button>
                                <div *ngIf="incrementing" class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="data.length==0" class="pb-5 pt-5 text-center col">
                            <h5 class="mt-5 mb-5">Nenhuma novidade a mostrar</h5>
                        </div>
                    </div>
                    <ng-container *ngIf="!data">
                        <div class="my_skeleton_loading mb-3" style="height: 180px; width: 100%;"></div>
                        <div class="my_skeleton_loading mb-3" style="height: 180px; width: 100%;"></div>
                        <div class="my_skeleton_loading mb-3" style="height: 180px; width: 100%;"></div>
                    </ng-container>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div>
</div>