import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { AuthenticationService } from '../services/auth.service';
import { switchMap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { UserProfileService } from 'src/app/core/services/user.service';
import { CoreService } from 'src/app/core/services/core.service';
import { map, tap, catchError, filter, scan } from 'rxjs/operators';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private usr: UserProfileService,
        private core: CoreService
    ) {

    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        if (request.headers.get("api")) {
            if (!request.headers.get("skip")) {
                return from(Preferences.get({ key: "token-jwt" })).pipe(switchMap((result: any) => {
                    let tokenValue = JSON.parse(result.value);
                    return from(Preferences.get({ key: "user" })).pipe(switchMap((user: any) => {
                        let userValue = JSON.parse(user.value);
                        if(!userValue) this.core.message('Ops ! Não foi possível processar sua solicitação. Gentileza realizar login novamente.', 'warning', 'top', 5000, [], 'auth/login');
                        let token = null;
                        let hasToken = false;
                        if (tokenValue) token = tokenValue;
                        if (token.value) if (!this.expiredtoken(token)) hasToken = true;
                        if (hasToken) {
                            request = request.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${token.value}`
                                }
                            });
                            return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                                if (event instanceof HttpResponse) {
                                    return this.updateResponse(event, userValue);
                                }

                                return event;
                            }));

                        } else {
                            return this.authenticationService.getToken().pipe(switchMap((res: any) => {
                                this.usr.setJwt(res.token)
                                request = request.clone({
                                    setHeaders: {
                                        Authorization: `Bearer ${res.token}`
                                    }
                                });
                                return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                                    if (event instanceof HttpResponse) {
                                        return this.updateResponse(event, userValue);
                                    }
                                    this.usr.checkSession();
                                    return event;
                                }));
                            }));
                        }
                    }));
                }));

            } else {

                return from(Preferences.get({ key: "user" })).pipe(switchMap((user: any) => {
                    let userValue = JSON.parse(user.value);
                    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            return this.updateResponse(event, userValue);
                        }


                        return event;
                    }));
                }));
            }
        } else {
            return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                return event;
            }));
        }

    }
    

    private expiredtoken(token: any) {
        return Date.now() >= token.dateExpire;
    }

    private updateResponse(event: HttpResponse<any>, user: any) {

        let key = "KEY-$PV5-BR44!L-w#yDigit@1."

        if (user != null) {
            key = (user.token).substr(-27);
        }

        if (typeof event.body.status != 'undefined' || typeof event.body.data != 'undefined') {
            var decrypted = event.body;
        } else {
            var decrypted = JSON.parse(CryptoJS.AES.decrypt(event.body, key).toString(CryptoJS.enc.Utf8)) // Message
        }

        let http: any = {
            body: decrypted,
            headers: event.headers,
            status: event.status,
            statusText: event.statusText,
            url: event.url
        }

        if(event.status==0){
            this.core.message('Sem conexão com a internet, por favor, ative os dados moveis ou wi-fi.', 'warning', 'bottom');
        }

        return new HttpResponse(http);
    }
}
