import { Injectable } from '@angular/core';
import { User } from '../models/auth.models';
import { Preferences } from '@capacitor/preferences';
import * as Enums from 'src/app/enums';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CoreService } from './core.service';
import { Subject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class UserProfileService {
    constructor(
        private http: HttpClient,
        public httpParams: HttpParams,
        public coreS: CoreService,
    ) { }

    private updateUser = new Subject<any>();
    private chekingSession: boolean = false;

    private headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        api: 'true',
    };

    getObservableUser(): Subject<any> {
        return this.updateUser;
    }
    /***
     * Get All User
     */
    getAll() {
        return this.http.get<User[]>(`api/users`);
    }

    updateScreenUser(data: any) {
        this.updateUser.next(data);
    }

    /***
     * Facked User Register
     */
    register(user: User) {
        return this.http.post(`/users/register`, user);
    }

    async setUser(data: any) {

        await Preferences.set({
            key: "user",
            value: JSON.stringify(data),
        });
        this.updateScreenUser(true);

    }

    async setJwt(value: any) {
        await Preferences.set({
            key: "token-jwt",
            value: JSON.stringify({
                value: value,
                dateExpire: Date.now() + 7100000
            }),
        });
    }

    async getJwt() {
        let token: any = await Preferences.get({ key: "token-jwt" })
        return JSON.parse(token.value);
    }

    async verifyPermission(permission: any) {
        let user = await this.getUser();
        return (user.permissions).includes(permission);
    }

    async verifyPagePermission(permission: any) {
        let result = await this.verifyPermission(permission);
        if (!result) {
            this.coreS.backDash();
        }
    }

    async getUser() {
        const response: any = await Preferences.get({ key: "user" });
        return JSON.parse(response.value);
    }

    async checkSession() {
        if(this.chekingSession) return;
        const user = await this.getUser();
        const patch = typeof user.session_id != "undefined" ? '?session_id='+user.session_id : '';
        var headers = new HttpHeaders(this.headers);
        this.chekingSession = true;
        this.http.get(Enums.VARS.API_BASE_URL + 'guard/profileUser/checkSessionTime'+patch, { headers: headers }).subscribe(async (response: any) => {
            this.chekingSession = false;
            if (response.status) {
                if(response.expired){
                    this.coreS.message(response.message, 'warning', 'top', 5000, [], 'auth/login');
                    this.removeUser();
                }
            } else {
                this.coreS.message('Sessão expirada, faça novo login para continuar utilizando o APVS Atende.', 'warning', 'top', 5000, [], 'auth/login');
                this.removeUser();
            }
        }, (error: any) => {
            this.chekingSession = false;
        })
    }

    async removeUser() {
        await Preferences.remove({ key: "user" });
        await Preferences.remove({ key: "token-jwt" });
    }

    getUsers(data = {}) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/users', JSON.stringify(data), { headers: headers });
    }

    forceLogoff(userId:number, sessionId:number|null = null) {
        var headers = new HttpHeaders(this.headers);
        const increment =  typeof sessionId == 'number' ? '/'+sessionId : '';
        return this.http.put(Enums.VARS.API_BASE_URL + 'guard/users/forceLogoff/'+userId+increment, JSON.stringify({}), { headers: headers });
    }

    getUserData() {
        var headers = new HttpHeaders(this.headers);
        return this.http.get(Enums.VARS.API_BASE_URL + 'guard/profileUser/getUserData', { headers: headers });
    }

    setUserData(data: any) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/profileUser/setUserData', JSON.stringify(data), { headers: headers });
    }

    changeUserPassword(data: any) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/profileUser/changeUserPassword', JSON.stringify(data), { headers: headers });
    }

    uploadProfilePhoto(data: any) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/profileUser/uploadProfilePhoto', JSON.stringify(data), { headers: headers });
    }


    getAllProfiles() {
        var headers = new HttpHeaders(this.headers);
        return this.http.get(Enums.VARS.API_BASE_URL + 'guard/users/getAllProfiles', { headers: headers });
    }

    editUser(data: any) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/users/editUser', JSON.stringify(data), { headers: headers });
    }

    insert(data: any) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/users/insert', JSON.stringify(data), { headers: headers });
    }

    editPasswordUser(data: any) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/users/editPasswordUser', JSON.stringify(data), { headers: headers });
    }



}
