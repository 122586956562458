const creatorData = [
  {
    id: 1,
    img: 'assets/images/nft/img-01.jpg',
    title: "Timothy Smith",
    price: "4,754 ETH",
  },
  {
      id: 2,
      img: 'assets/images/users/avatar-5.jpg',
      title: "Alexis Clarke",
      price: "81,369 ETH",
  },
  {
      id: 3,
      img: 'assets/images/nft/img-06.jpg',
      title: "Glen Matney",
      price: "13,156 ETH",
  },
  {
      id: 4,
      img: 'assets/images/nft/gif/img-5.gif',
      title: "Herbert Stokes",
      price: "34,754 ETH",
  },
  {
      id: 5,
      img: 'assets/images/users/avatar-8.jpg',
      title: "Michael Morris",
      price: "13,841 ETH",
  },
  {
      id: 6,
      img: 'assets/images/nft/img-02.jpg',
      title: "James Morris",
      price: "63,710 ETH",
  },
];
  
export { creatorData };
  