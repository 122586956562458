import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Enums from 'src/app/enums';
import { Subject } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { CoreService } from './core.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpClient,
    public httpParams: HttpParams
  ) { }

  private headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    api: 'true',
  };

  private updateNotification = new Subject<any>();

  observableNotification(): Subject<any> {
    return this.updateNotification;
  }

  updateObservableNotification(value: boolean) {
    this.updateNotification.next(value);
  }

  async setNotificationStorage(value: string) {
    await Preferences.set({
      key: "countNotifications",
      value: value,
    });
  }

  async getNotificationStorage() {
    const response: any = await Preferences.get({ key: "countNotifications" });
    return response.value;
  }

  counRows() {
    var headers = new HttpHeaders(this.headers);
    return this.http.get(Enums.VARS.API_BASE_URL + 'guard/notifications/coountNotifications', { headers: headers });
  }

  getAll(page = 1) {
    var headers = new HttpHeaders(this.headers);
    return this.http.post(Enums.VARS.API_BASE_URL + 'guard/notifications/getAll', JSON.stringify({ page: page }), { headers: headers });
  }

  get(){
    var headers = new HttpHeaders(this.headers);
    return this.http.get(Enums.VARS.API_BASE_URL + 'guard/notifications/get', { headers: headers });
  }

  /**
   * Enfileira notificações
   * @param title - Título da notificação
   * @param message - Mensagem da notificação
   * @param url - Url da notificação
   * @param docs - CPF's e/ou CNPJ's dos usuarios que receberão a notificação
   * @param user_id - Id do usuário que receberá a notificação
   * @param profile_id - Perfil/departamento do usuario que receberá a notificação
   * @param push - Enviar por push?
   * @param associates_only - Apenas associados
   * @param nickname - Apelido da notificação
   */
  queue(title:string, message:string, nickname: string, url:string|null = null, docs:string|null = null, user_id:string|null = null, profile_id:string|null = null, associates_only:boolean=false, push:boolean=false) {
    var headers = new HttpHeaders(this.headers);
    return this.http.post(Enums.VARS.API_BASE_URL + 'guard/notifications/queue',{
      title: title,
      message: message,
      nickname: nickname,
      url: url,
      docs: docs,
      user_id: user_id,
      profile_id: profile_id,
      associates_only: associates_only,
      push: push
    }, { headers: headers });
  }

  /**
   * 
   * @param params - page, rows, reverse, order_column, nickname, title, message, push, description
   * @returns 
   */
  getQueueLogs(params:{
    page: number|undefined,
    rows: number|undefined,
    reverse: number|undefined,
    order_column: string|undefined,
    nickname: string|undefined,
    title: string|undefined,
    message: string|undefined,
    push: string|number|undefined,
    description: string|undefined
  }) {
    var headers = new HttpHeaders(this.headers);
    const query = CoreService.generateQueryParamsWithObject(params);
    return this.http.get(Enums.VARS.API_BASE_URL + 'guard/notifications/getQueueLogs' + query, { headers: headers });
  }


}
